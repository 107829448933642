<template>
  <div class="borderColor borderColorBackground mb-4">
    <div class="d-flex align-items-center flex-wrap text-center mb-3">
      <div
        class="font-weight-bold pageButton datepickerButton"
        :class="{ active: order === 1 }"
        @click="handleChangePage(1)"
      >
        串接資料
      </div>
      <div
        class="font-weight-bold pageButton datepickerButton"
        :class="{ active: order === 2 }"
        @click="handleChangePage(2)"
      >
        PVsyst
      </div>
      <div
        class="font-weight-bold pageButton datepickerButton"
        :class="{ active: order === 3 }"
        @click="handleChangePage(3)"
      >
        檔案資料
      </div>
      <div
        class="font-weight-bold pageButton datepickerButton"
        :class="{ active: order === 4 }"
        @click="handleChangePage(4)"
      >
        KPI設定
      </div>

      <div
        class="d-flex align-items-center ml-auto"
        v-if="order !== 3 && !resError"
      >
        <div>
          <template v-if="order === 4">
            <label class="m-1">
              基礎PR衰退率:
              <input
                v-model="basicDeclineRate"
                min="0"
                type="number"
                style="width: 100px"
              />
            </label>
            <label class="m-1">
              逐年PR衰退率:
              <input
                v-model="declineRate"
                min="0"
                type="number"
                style="width: 100px"
              />
            </label>
            <label class="m-1">
              新增幾年:
              <input
                v-model="newDataLength"
                min="1"
                type="number"
                style="width: 50px"
              />
            </label>

            <button
              type="button"
              class="confirmButton mx-1"
              @click="kpiSettingNewData"
            >
              新增
            </button>
          </template>

          <button
            type="button"
            class="confirmButton mx-1"
            @click="isEditing = !isEditing"
          >
            {{ isEditing ? "確認" : "修改" }}
          </button>

          <b-button
            v-b-tooltip.hover
            type="button"
            :title="isEditing ? '請確認修改資料' : ''"
            class="confirmButton mx-1"
            :style="{ cursor: isEditing ? 'not-allowed' : 'pointer' }"
            :disabled="isEditing"
            @click="handleUpdateData(order)"
          >
            上傳
          </b-button>
        </div>
      </div>
    </div>

    <div class="text-center font-weight-bold" v-if="order === 4">
      PR衰退公式 => 起始年PR - (((基礎PR衰退率 + ((第幾年 - 1) * 逐年PR衰退率)))
      * 起始年PR)
    </div>

    <div class="overflow-auto bg-white">
      <b-spinner v-if="isLoading" variant="secondary" class="mx-3 my-4" />

      <b-alert
        :show="resError"
        variant="secondary"
        class="text-center my-2 mx-1 font-weight-bold"
      >
        目前暫無資料，待資料上傳
      </b-alert>

      <!-- 串接資料、PVsyst table -->
      <table class="w-100 text-center" v-if="order === 1 || order === 2">
        <tr>
          <th v-for="(i, idx) in tableHeader" :key="idx">
            <input
              v-if="isEditing"
              :value="i.indexOf('__EMPTY') === -1 ? i : ''"
              class="text-center"
              style="width: 100px"
              @input="
                changeTableTitle($event.target.value, idx, tableHeader[idx])
              "
            />
            <span v-else>{{ i.indexOf("__EMPTY") === -1 ? i : "" }}</span>
          </th>
        </tr>
        <tr v-for="(i, idx) in tableData" :key="idx">
          <td v-for="(j, idx) in tableHeader" :key="idx">
            <input
              v-if="isEditing"
              :value="i[j]"
              class="text-center"
              style="width: 100px"
              @input="i[j] = $event.target.value"
            />
            <span v-else>{{ i[j] }}</span>
          </td>
        </tr>
      </table>

      <!-- 檔案資料 table -->
      <table v-if="order === 3 && !!pdfArray.length" class="w-100 text-center">
        <tr>
          <th>檔名</th>
          <th v-if="dataAdmin.includes(userDetail.role)">刪除</th>
        </tr>
        <tr v-for="(i, idx) in pdfArray" :key="idx">
          <td>
            <a :href="i.value" target="_blank">{{ i.name }}</a>
          </td>
          <td v-if="dataAdmin.includes(userDetail.role)">
            <button
              type="button"
              class="deleteButton m-5px"
              style="width: 70px"
              @click="checkDeletePdf(i.name)"
            >
              X
            </button>
          </td>
        </tr>
      </table>

      <!-- KPI 設定  -->
      <template v-if="order === 4">
        <!-- KPI 資料輸入 button -->
        <div
          class="d-flex align-items-center mt-2"
          style="margin-bottom: -25px"
        >
          <label class="btn btn-sm btn-info m-0 mr-2">
            <i class="fas fa-file-upload" />&ensp;KPI 資料輸入
            <input
              type="file"
              class="border border-black rounded d-none"
              accept=".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              @change="handleUploadInputKpi"
            />
          </label>
          <div
            v-if="uploadKpiFileName"
            class="text-secondary"
            style="font-size: 14px"
          >
            {{ uploadKpiFileName }}
          </div>
        </div>

        <!-- table -->
        <div
          v-for="(i, idx) in kpiSetting"
          :key="idx"
          class="kpiSettingTable mt-5"
        >
          <div class="d-flex align-items-center justify-content-between">
            <b-form-checkbox
              :id="String(idx)"
              v-model="kpiSettingManualList[idx]"
              class="ml-1"
            >
              啟用手動設定
            </b-form-checkbox>

            <button
              type="button"
              v-if="kpiSetting.length > 1"
              class="deleteButton mb-1"
              @click="kpiSettingSplice(idx)"
            >
              移除
            </button>
          </div>

          <div class="overflow-auto">
            <table class="w-100 text-center">
              <tr>
                <th>
                  <span v-if="!isEditing">{{ i.year }}</span>
                  <input
                    v-else
                    :value="i.year"
                    class="w-75"
                    @input="i.year = $event.target.value"
                  />
                </th>
                <th
                  v-for="month in monthArray"
                  :key="month"
                  class="text-black-50"
                >
                  {{ month }}
                </th>
              </tr>
              <tr>
                <td>目標 PR</td>
                <td v-for="(n, idx) in i.PR" :key="idx">
                  <span v-if="!isEditing">{{ n }}</span>
                  <input
                    v-else
                    :value="n"
                    @input="i.PR[idx] = Number($event.target.value)"
                  />
                </td>
              </tr>
              <tr>
                <td>妥善率</td>
                <td v-for="(n, idx) in i.properRate" :key="idx">
                  <span v-if="!isEditing">{{ n }}</span>
                  <input
                    v-else
                    :value="n"
                    @input="i.properRate[idx] = Number($event.target.value)"
                  />
                </td>
              </tr>
              <tr>
                <td>峰瓩值</td>
                <td v-for="(n, idx) in i.peakWatt" :key="idx">
                  <span v-if="!isEditing">{{ n }}</span>
                  <input
                    v-else
                    :value="n"
                    @input="i.peakWatt[idx] = Number($event.target.value)"
                  />
                </td>
              </tr>
              <tr>
                <td>保證 PR</td>
                <td v-for="(n, idx) in i.guaranteePr" :key="idx">
                  <span v-if="!isEditing">{{ n }}</span>
                  <input
                    v-else
                    :value="n"
                    @input="i.guaranteePr[idx] = Number($event.target.value)"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-nowrap">保證發電量</td>
                <td v-for="(n, idx) in i.guaranteePower" :key="idx">
                  <span v-if="!isEditing">{{ n }}</span>
                  <input
                    v-else
                    :value="n"
                    @input="i.guaranteePower[idx] = Number($event.target.value)"
                  />
                </td>
              </tr>
              <tr>
                <td class="text-nowrap">目標發電量</td>
                <td v-for="(n, idx) in i.acpGoalContract" :key="idx">
                  <span v-if="!isEditing">{{ n }}</span>
                  <input
                    v-else
                    :value="n"
                    @input="
                      i.acpGoalContract[idx] = Number($event.target.value)
                    "
                  />
                </td>
              </tr>

              <template v-if="kpiSettingManualList[idx]">
                <tr>
                  <td class="text-nowrap">設定&ensp;[ 日照量 ]</td>
                  <td v-for="(n, idx) in i.irrActualManual" :key="idx">
                    <span v-if="!isEditing">{{ n }}</span>
                    <input
                      v-else
                      :value="n"
                      @input="
                        i.irrActualManual[idx] = Number($event.target.value)
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">設定&ensp;[ 發電量 ]</td>
                  <td v-for="(n, idx) in i.acpActualManual" :key="idx">
                    <span v-if="!isEditing">{{ n }}</span>
                    <input
                      v-else
                      :value="n"
                      @input="
                        i.acpActualManual[idx] = Number($event.target.value)
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">設定&ensp;[ PR ]</td>
                  <td v-for="(n, idx) in i.PRActualManual" :key="idx">
                    <span v-if="!isEditing">{{ n }}</span>
                    <input
                      v-else
                      :value="n"
                      @input="
                        i.PRActualManual[idx] = Number($event.target.value)
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">設定&ensp;[ Data_down ]</td>
                  <td
                    v-for="(n, idx) in i.availabilityManual?.data_down"
                    :key="idx"
                  >
                    <span v-if="!isEditing">{{ n }}</span>
                    <input
                      v-else
                      :value="n"
                      @input="
                        i.availabilityManual.data_down[idx] = Number(
                          $event.target.value
                        )
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">設定&ensp;[ T_useful ]</td>
                  <td
                    v-for="(n, idx) in i.availabilityManual?.t_useful"
                    :key="idx"
                  >
                    <span v-if="!isEditing">{{ n }}</span>
                    <input
                      v-else
                      :value="n"
                      @input="
                        i.availabilityManual.t_useful[idx] = Number(
                          $event.target.value
                        )
                      "
                    />
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">設定&ensp;[ T_down ]</td>
                  <td
                    v-for="(n, idx) in i.availabilityManual?.t_down"
                    :key="idx"
                  >
                    <span v-if="!isEditing">{{ n }}</span>
                    <input
                      v-else
                      :value="n"
                      @input="
                        i.availabilityManual.t_down[idx] = Number(
                          $event.target.value
                        )
                      "
                    />
                  </td>
                </tr>
              </template>
            </table>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { utils, read } from 'xlsx'
import {
  createKpiTableApi,
  getKpiTableApi,
  getPdfApi,
  getCsvApi,
  updateCsvApi,
  deletePdfApi
} from '../api'
import { BAlert, BButton, BSpinner } from 'bootstrap-vue'
import { mapState } from 'vuex'

const monthArray = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export default {
  name: 'DataExcel',
  components: {
    BAlert,
    BButton,
    BSpinner
  },
  data () {
    return {
      basicDeclineRate: 0.03,
      declineRate: 0.007,
      newDataLength: 1,
      monthArray,
      uploadKpiFile: undefined,
      uploadKpiFileName: '',
      kpiSetting: [
        {
          year: '2021-預設值請修改',
          PR: [80, 82, 91, 80, 82, 91, 80, 82, 91, 80, 82, 91],
          properRate: Array(12).fill(98),
          peakWatt: [
            3000, 2500, 1000, 3000, 2500, 1000, 3000, 2500, 1000, 3000, 2500,
            1000
          ],
          guaranteePr: Array(12).fill(0),
          guaranteePower: Array(12).fill(0),
          acpGoalContract: Array(12).fill(0),
          irrActualManual: Array(12).fill(0),
          acpActualManual: Array(12).fill(0),
          PRActualManual: Array(12).fill(0),
          availabilityManual: {
            data_down: Array(12).fill(0),
            t_useful: Array(12).fill(0),
            t_down: Array(12).fill(0)
          }
        }
      ],
      kpiSettingByApi: [],
      kpiSettingManualList: [], // [true, false, true,... ]
      kpiSettingManualListByApi: [], // [true, false, true,... ]
      pdfArray: [],
      isEditing: false,
      tableData: [],
      tableHeader: [],
      csvName: '',
      resError: false,
      order: 1,
      isLoading: false
    }
  },
  computed: {
    ...mapState(['dataAdmin', 'userDetail'])
  },
  methods: {
    handleChangePage (order) {
      this.isLoading = true
      this.isEditing = false
      this.order = order
      switch (order) {
        case 1:
          this.tableData = []
          this.tableHeader = []
          this.csvName = ''
          this.getcsv('_串列資料.json')
          break
        case 2:
          this.tableData = []
          this.tableHeader = []
          this.csvName = ''
          this.getcsv('_PVSYS.json')
          break
        case 3:
          this.pdfArray = []
          this.getPdf()
          break
        case 4:
          this.kpiSetting = []
          this.getKpiTable()
          break
      }
      this.resError = false
    },
    handleUpdateData (order) {
      if (order === 1 || order === 2) {
        this.updateCsv()
      }

      if (order === 4) {
        this.updateKpiSetting()
      }
    },
    handleUploadInputKpi (e) {
      if (e.target.files?.length === 0) {
        this.uploadKpiFile = undefined // 上傳後又取消，清除要上傳的暫存
        this.uploadKpiFileName = ''
        this.kpiSetting = [...this.kpiSettingByApi]
        this.kpiSettingManualList = [...this.kpiSettingManualListByApi]
        return
      }

      const [file] = e.target.files
      // check file name (file.name)
      const reader = new FileReader()

      // 讀取成 ArrayBuffer
      reader.readAsArrayBuffer(file)

      // 讀取成功
      reader.onloadend = (e) => {
        const data = e.target?.result
        const workbook = read(data, { type: 'array' })

        // year list
        const firstWorkSheet = workbook.Sheets['目標PR']
        // 檢查 '!ref' 是否存在
        const firstSheetRef = firstWorkSheet['!ref']
        if (!firstSheetRef) return this.$swal('工作表範圍未定義')
        let columnA = []
        const range = utils.decode_range(firstSheetRef) // 取得工作表的範圍
        for (let row = range.s.r; row <= range.e.r; row++) {
          // 遍歷所有行
          const cellAddress = `A${row + 1}` // 建立單元格地址，如 A1, A2, A3
          const cell = firstWorkSheet[cellAddress]
          // columnA.push(utils.format_cell(cell));
          columnA.push(cell?.v)
        }
        const yearList = columnA
          .filter((v) => v && v !== '西元年別')
          .map((v) => String(v))

        // deal with excel raw data
        const dataKeys = [
          'PR',
          'properRate',
          'peakWatt',
          'guaranteePr',
          'guaranteePower',
          'acpGoalContract'
        ]
        const dataKeyNames = [
          '目標PR',
          '妥善率',
          '峰瓩值',
          '保證PR',
          '保證發電量',
          '目標發電量'
        ]

        let allProcessedJsonData = {}
        dataKeyNames.forEach((sheetName, idx) => {
          const workSheet = workbook.Sheets[sheetName]
          const sheetJson = utils.sheet_to_json(workSheet)
          const processedJsonData = sheetJson.reduce((accu, curr) => {
            const year = curr['西元年別']

            accu[year] = monthArray.map(
              (month) => Math.round(curr[month] * 100) / 100 || 0
            )

            return accu
          }, {})

          allProcessedJsonData[dataKeys[idx]] = processedJsonData
        })

        // final excel data
        const defaultData = Array(12).fill(0)
        const finalExcelData = yearList.map((year, _idx) => {
          return {
            year,
            PR: allProcessedJsonData?.PR?.[year] || defaultData,
            properRate: allProcessedJsonData?.properRate?.[year] || defaultData,
            peakWatt: allProcessedJsonData?.peakWatt?.[year] || defaultData,
            guaranteePr:
              allProcessedJsonData?.guaranteePr?.[year] || defaultData,
            guaranteePower:
              allProcessedJsonData?.guaranteePower?.[year] || defaultData,
            acpGoalContract:
              allProcessedJsonData?.acpGoalContract?.[year] || defaultData
          }
        })
        // console.log(finalExcelData)

        this.uploadKpiFile = finalExcelData
        this.uploadKpiFileName = file.name

        this.setUploadExcelToKpiSetting(this.uploadKpiFile)
      }

      // 讀取失敗
      reader.onerror = (err) => console.error('handleFileChange', err)
    },
    setUploadExcelToKpiSetting (uploadedExcelData) {
      // 原本資料有啟用手動設定 Map
      const originalManualMap = new Map()
      this.kpiSetting.forEach((originObj, originIdx) => {
        if (this.kpiSettingManualList[originIdx]) {
          return originalManualMap.set(originObj.year, originObj)
        }
      })

      this.kpiSetting = uploadedExcelData.map((obj, idx) => {
        if (originalManualMap.has(obj.year)) {
          this.kpiSettingManualList[idx] = !!originalManualMap.has(obj.year)
          const copyObj = originalManualMap.get(obj.year)
          return {
            ...copyObj,
            ...obj
          }
        }

        this.kpiSettingManualList[idx] = !!originalManualMap.has(obj.year)
        return {
          ...obj,
          irrActualManual: Array(12).fill(0),
          acpActualManual: Array(12).fill(0),
          PRActualManual: Array(12).fill(0),
          availabilityManual: {
            data_down: Array(12).fill(0),
            t_useful: Array(12).fill(0),
            t_down: Array(12).fill(0)
          }
        }
      })
    },
    kpiSettingSplice (idx) {
      this.kpiSetting.splice(idx, 1)
      this.kpiSettingManualList.splice(idx, 1)
    },
    kpiSettingNewData () {
      if (this.kpiSetting[0].year.split('-')[1] === '預設值請修改') {
        return this.$swal('請先修改預設值')
      }

      // 新增幾年 -> 新增幾筆
      Array.from({ length: this.newDataLength }).forEach(() => {
        const lastLength = this.kpiSetting.length - 1
        const lastData = JSON.parse(
          JSON.stringify(this.kpiSetting[lastLength])
        )
        this.kpiSetting.push({
          ...lastData,
          irrActualManual: Array(12).fill(0),
          acpActualManual: Array(12).fill(0),
          PRActualManual: Array(12).fill(0),
          availabilityManual: {
            data_down: Array(12).fill(0),
            t_useful: Array(12).fill(0),
            t_down: Array(12).fill(0)
          }
        })
        this.kpiSetting[this.kpiSetting.length - 1].year = `${
          Number(this.kpiSetting[this.kpiSetting.length - 1].year) + 1
        }`
        const diffYear =
          this.kpiSetting[this.kpiSetting.length - 1].year -
          this.kpiSetting[0].year
        // PR 衰退
        // 第一年衰減 3%, 第二年(含)以後各年衰減 0.7%
        // 各年與初始值(第0年)相比的衰減幅度公式為 3%+(n-1)*0.7%, n 是第幾年
        let declinePr = JSON.parse(JSON.stringify(this.kpiSetting[0].PR))
        declinePr = declinePr.map((x) =>
          Math.round(
            x -
              (Number(this.basicDeclineRate) +
                (diffYear - 1) * Number(this.declineRate)) *
                x
          )
        )
        this.kpiSetting[this.kpiSetting.length - 1].PR = declinePr
      })
    },
    async updateKpiSetting () {
      if (this.kpiSetting[0].year.split('-')[1] === '預設值請修改') {
        this.$swal('請先修改預設值')
        return
      }

      // check 上傳 manual 值
      const updateKpiSettingData = this.kpiSetting.map((obj, idx) => {
        if (this.kpiSettingManualList[idx]) {
          return obj
        } else {
          const {
            year,
            PR,
            properRate,
            peakWatt,
            guaranteePr,
            guaranteePower,
            acpGoalContract
          } = obj
          return {
            year,
            PR,
            properRate,
            peakWatt,
            guaranteePr,
            guaranteePower,
            acpGoalContract
          }
        }
      })

      const updateKpi = await createKpiTableApi(
        `${this.$route.query.siteName}.json`,
        updateKpiSettingData
      )
      if (updateKpi?.data?.success) {
        this.$swal('上傳成功')
        this.getKpiTable()
      }
    },
    async getKpiTable () {
      const getKpi = await getKpiTableApi(
        `${this.$route.query.siteName}.json`
      )
      if (getKpi?.data?.success) {
        this.kpiSetting = getKpi.data.data.map((obj, idx) => {
          // 判斷 Manual 欄位
          // 有一 Manual 即其餘全有
          this.kpiSettingManualList[idx] = !!obj.acpActualManual

          // 有 data 但未滿 12 ---

          const MONTHS_IN_YEAR = 12
          if (
            obj.irrActualManual &&
            obj.irrActualManual.length !== MONTHS_IN_YEAR
          ) {
            const fillArrayLength = MONTHS_IN_YEAR - obj.irrActualManual.length
            Object.assign(obj, {
              irrActualManual: [
                ...obj.irrActualManual,
                ...Array(fillArrayLength).fill(0)
              ]
            })
          }

          if (
            obj.acpActualManual &&
            obj.acpActualManual.length !== MONTHS_IN_YEAR
          ) {
            const fillArrayLength = MONTHS_IN_YEAR - obj.acpActualManual.length
            Object.assign(obj, {
              acpActualManual: [
                ...obj.acpActualManual,
                ...Array(fillArrayLength).fill(0)
              ]
            })
          }

          if (
            obj.PRActualManual &&
            obj.PRActualManual.length !== MONTHS_IN_YEAR
          ) {
            const fillArrayLength = MONTHS_IN_YEAR - obj.PRActualManual.length
            Object.assign(obj, {
              PRActualManual: [
                ...obj.PRActualManual,
                ...Array(fillArrayLength).fill(0)
              ]
            })
          }

          if (
            obj.availabilityManual?.data_down &&
            obj.availabilityManual?.data_down?.length !== MONTHS_IN_YEAR
          ) {
            const fillArrayLength =
              MONTHS_IN_YEAR - obj.availabilityManual.data_down.length
            Object.assign(obj, {
              availabilityManual: {
                ...obj.availabilityManual,
                data_down: [
                  ...obj.availabilityManual.data_down,
                  ...Array(fillArrayLength).fill(0)
                ]
              }
            })
          }

          if (
            obj.availabilityManual?.t_useful &&
            obj.availabilityManual?.t_useful?.length !== MONTHS_IN_YEAR
          ) {
            const fillArrayLength =
              MONTHS_IN_YEAR - obj.availabilityManual.t_useful.length
            Object.assign(obj, {
              availabilityManual: {
                ...obj.availabilityManual,
                t_useful: [
                  ...obj.availabilityManual.t_useful,
                  ...Array(fillArrayLength).fill(0)
                ]
              }
            })
          }

          if (
            obj.availabilityManual?.t_down &&
            obj.availabilityManual?.t_down?.length !== MONTHS_IN_YEAR
          ) {
            const fillArrayLength =
              MONTHS_IN_YEAR - obj.availabilityManual.t_down.length
            Object.assign(obj, {
              availabilityManual: {
                ...obj.availabilityManual,
                t_down: [
                  ...obj.availabilityManual.t_down,
                  ...Array(fillArrayLength).fill(0)
                ]
              }
            })
          }

          // 無 data ---

          if (!obj.guaranteePr) {
            Object.assign(obj, { guaranteePr: Array(12).fill(0) })
          }

          if (!obj.guaranteePower) {
            Object.assign(obj, { guaranteePower: Array(12).fill(0) })
          }

          if (!obj.acpGoalContract) {
            Object.assign(obj, { acpGoalContract: Array(12).fill(0) })
          }

          if (!obj.irrActualManual) {
            Object.assign(obj, { irrActualManual: Array(12).fill(0) })
          }

          if (!obj.acpActualManual) {
            Object.assign(obj, { acpActualManual: Array(12).fill(0) })
          }

          if (!obj.PRActualManual) {
            Object.assign(obj, { PRActualManual: Array(12).fill(0) })
          }

          if (!obj.availabilityManual?.data_down) {
            Object.assign(obj, {
              availabilityManual: {
                ...obj.availabilityManual,
                data_down: Array(12).fill(0)
              }
            })
          }

          if (!obj.availabilityManual?.t_useful) {
            Object.assign(obj, {
              availabilityManual: {
                ...obj.availabilityManual,
                t_useful: Array(12).fill(0)
              }
            })
          }

          if (!obj.availabilityManual?.t_down) {
            Object.assign(obj, {
              availabilityManual: {
                ...obj.availabilityManual,
                t_down: Array(12).fill(0)
              }
            })
          }

          return obj
        })
      }

      // 另存 api 的資料 (api 有存 api 的，沒有存 this.kpiSetting)
      this.kpiSettingByApi = [...this.kpiSetting]
      this.kpiSettingManualListByApi = [...this.kpiSettingManualList]

      this.isLoading = false
    },
    changeTableTitle (s, idx, i) {
      const name = i
      this.tableHeader[idx] = s
      this.tableData.forEach((x) => {
        Object.defineProperty(x, s, Object.getOwnPropertyDescriptor(x, name))
        delete x[name]
      })
    },
    async getPdf () {
      const pdf = await getPdfApi(this.$route.query.fid)
      if (pdf?.data?.success) {
        const arr = []
        pdf.data.data.forEach((x) => {
          const nameSplit = x.split('/')
          arr.push({
            name: nameSplit[nameSplit.length - 1],
            value: x
          })
        })
        this.pdfArray = arr
        if (this.pdfArray.length === 0) this.resError = true
      }
      this.isLoading = false
    },
    async getcsv (str) {
      const fileName = this.$route.query.siteName + str
      const csv = await getCsvApi(fileName)
      if (csv?.data?.success) {
        this.csvName = csv.data.data.fileName
        this.tableData = csv.data.data.csvData
        this.tableHeader = csv.data.data.csvHeader
      } else {
        this.resError = true
      }
      this.isLoading = false
    },
    async updateCsv () {
      const csvHeader = this.tableHeader
      const csvData = this.tableData
      const update = await updateCsvApi(this.csvName, csvHeader, csvData)
      if (update?.data?.success) {
        this.$swal('儲存成功')
      }
    },
    async checkDeletePdf (pdfName) {
      this.$swal
        .fire({
          title: `確定要刪除?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: `確定`,
          denyButtonText: `否`
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deletePdf(pdfName)
          }
        })
    },
    async deletePdf (pdfName) {
      const deletePdf = await deletePdfApi(
        this.$route.query.siteName,
        pdfName
      )
      if (deletePdf?.data?.success) {
        this.$swal('刪除成功')
        this.getPdf()
      }
    }
  },
  created () {
    this.getcsv('_串列資料.json')
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/customVariables.scss";
table th {
  padding: 3px;
  min-width: 205px;
  border: solid 1px rgb(236, 236, 236);
  background-color: rgb(246, 247, 245);
}

table td {
  font-size: 17px;
  font-weight: 700;
  border: solid 1px rgb(236, 236, 236);
  padding: 5px 30px;
}

.pageButton {
  min-width: 140px;

  &:hover {
    background-color: rgba(114, 114, 114, 0.596);
  }

  &.active {
    color: white;
    background: linear-gradient(to top, $acme-blue-3, $acme-blue-1);
  }

  @media screen and (max-width: 520px) {
    width: 100%;
    max-width: 48%;
    margin: 4px 2px;
  }
}

.kpiSettingTable {
  table th {
    min-width: auto;
  }

  input {
    text-align: center;
    width: 78px;
  }
}
</style>
